import { PageHeader } from "antd";
import React from "react";

// displays a page header

export default function Header() {
  return (
    <a href="/" rel="noopener noreferrer">
      <PageHeader title="🎨 0xPalette" subTitle="A game of colors on the blockchain" style={{ cursor: "pointer" }} />
    </a>
  );
}
