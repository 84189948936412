import React, { useState, useEffect } from "react";
import randomcolor from "randomcolor";
import { Button, Row, Col, Card, Typography } from "antd";
import { ethers } from "ethers";
import { Address } from "../components";
import { useContractReader } from "eth-hooks";

const zero = ethers.BigNumber.from("0");

const splitAlpha = color => {
  const rgb = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
  const alpha = rgb.pop().toString().split(".");

  return [rgb, alpha];
};

function Home({ tx, readContracts, writeContracts, mainnetProvider, localProvider }) {
  const [shades, setShades] = useState({
    topLeft: "rgba(0, 0, 0, 0)",
    topRight: "rgba(0, 0, 0, 0)",
    bottomLeft: "rgba(0, 0, 0, 0)",
    bottomRight: "rgba(0, 0, 0, 0)",
  });
  const [latestMint, setLatestMint] = useState([]);

  const totalMinted = (useContractReader(readContracts, "_0xPalette", "totalSupply", []) || zero).toNumber();

  const rotateShades = () => {
    const colors = [];
    const lum = ["light", "bright", "dark", "light"];
    let last = "light";

    do {
      // let last = lum[colors.length];
      // console.log(last);
      // const color = randomcolor({ hue: "random", format: "rgba" });
      const color = randomcolor({ luminosity: lum[colors.length], hue: "random", format: "rgba" });

      if (!colors.includes(color)) {
        colors.push(color);
        last = last === "light" ? "bright" : "light";
      }
    } while (colors.length < 4);

    last = "random";

    // console.log(colors);

    setShades({
      topLeft: colors[0],
      topRight: colors[1],
      bottomLeft: colors[2],
      bottomRight: colors[3],
    });
  };

  const handleMint = async () => {
    const [topLeftRGB, topLeftAlpha] = splitAlpha(shades.topLeft);
    const [topRightRGB, topRightAlpha] = splitAlpha(shades.topRight);
    const [bottomLeftRGB, bottomLeftAlpha] = splitAlpha(shades.bottomLeft);
    const [bottomRightRGB, bottomRightAlpha] = splitAlpha(shades.bottomRight);

    const palletteShades = [...topLeftRGB, ...topRightRGB, ...bottomLeftRGB, ...bottomRightRGB];
    const alphas = [topLeftAlpha, topRightAlpha, bottomLeftAlpha, bottomRightAlpha];

    tx(
      writeContracts._0xPalette.mint(palletteShades, alphas, { value: ethers.utils.parseUnits("0.01337") }),
      // writeContracts._0xPalette.adminMint(palletteShades, alphas),
      update => {
        console.log("📡 Transaction Update:", update);
        if (update && (update.status === "confirmed" || update.status === 1)) {
          console.log(" 🍾 Transaction " + update.hash + " finished!");
          console.log(
            " ⛽️ " +
              update.gasUsed +
              "/" +
              (update.gasLimit || update.gas) +
              " @ " +
              parseFloat(update.gasPrice) / 1000000000 +
              " gwei",
          );
        }
      },
    );
  };

  useEffect(() => {
    rotateShades();
  }, []);

  const updateLatestMint = async () => {
    const myCollectionUpdate = [];
    let startIndex = 1;

    if (totalMinted > 8) {
      startIndex = totalMinted - 7;
    } else {
      startIndex = 1;
    }

    for (let i = startIndex; i <= totalMinted; i++) {
      try {
        const _tokenURI = (await readContracts._0xPalette.tokenURI(i)) || "";
        const owner = await readContracts._0xPalette.ownerOf(i);

        const [format, tokenURI] = _tokenURI.split(";base64,");

        try {
          // decode base64 here
          const jsonManifest = JSON.parse(decodeURIComponent(escape(window.atob(tokenURI))));
          // console.log("jsonManifest", jsonManifest);
          myCollectionUpdate.push({ id: i, owner, ...jsonManifest });
        } catch (e) {
          console.log(e);
        }
      } catch (error) {
        console.log(`Error on index ${i}`, error);
      }
    }

    setLatestMint(myCollectionUpdate.reverse());
  };

  useEffect(() => {
    updateLatestMint();
  }, [totalMinted]);

  return (
    <div className="flex flex-1 flex-col justify-center items-center text-center mx-4" style={{ lineHeight: "0" }}>
      <div>
        <h1 className="text-2xl">Welcome to the 0xPalette game.</h1>
        <div className="text-lg max-w-2xl">
          <Typography.Paragraph>
            Hello fren 👋🏾. I am{" "}
            <a href="https://twitter.com/ghostffcode" target="_blank">
              @ghostffcode
            </a>
            , and this is an on-chain experiment on ownership, royalties & derivatives.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Over the past year, I've minted a s#!t 💩 ton of NFTs and less than 4% of those have actually built utility
            into their tokens 🥴. We hear so much about how NFTs can truly change how we think about ownership and
            royalties; I'm creating 0xPalette to actually try to deliver.
          </Typography.Paragraph>

          <Typography.Paragraph>
            Here's my plan for 0xPalette:
            <div className="my-1">
              <span className="font-bold">Step 1 [The mint]:</span> You get to mint your palette of choice.{" "}
              <span className="italic">There are only 8888 tokens to be minted at Ξ 0.01337/token</span>.
            </div>
            <div className="mb-1">
              <span className="font-bold">Step 2 [The setup | Q2 2022]:</span> Introduction of the palette manager. You
              can name colors in your palette(s) and assign royalties percentages to each for later use.
            </div>
            <div className="mb-1">
              <span className="font-bold">Step 3 [Expansion | from Q3 2022]:</span> Building of curation tools. Empower
              and incentivize creatives to utilize these palettes.
            </div>
            <div className="mt-4">How's that for a token roadmap?</div>
          </Typography.Paragraph>
        </div>
      </div>

      <div className="mt-8">
        <div>
          <h2 className="font-bold text-lg mb-6">Mint Your Palette Of Choice</h2>
        </div>
        <div className="w-80 h-80">
          <div className="inline-flex m-0 p-0 w-40 h-40" style={{ backgroundColor: shades.topLeft }} />
          <div className="inline-flex m-0 p-0 w-40 h-40" style={{ backgroundColor: shades.topRight }} />
          <div className="inline-flex m-0 p-0 w-40 h-40" style={{ backgroundColor: shades.bottomLeft }} />
          <div className="inline-flex m-0 p-0 w-40 h-40" style={{ backgroundColor: shades.bottomRight }} />
        </div>
      </div>

      <div className="mt-8 flex flex-1 flex-col items-center justify-center w-full">
        {/* <Slider min={0} max={360} value={degree} className="w-full max-w-xl" onChange={setDegree} /> */}
        <div>
          <Button className="mr-4" onClick={rotateShades}>
            Shuffle Palette
          </Button>
          <Button onClick={handleMint}>Mint for Ξ 0.01337</Button>
        </div>
        <div className="mt-4 font-medium text-base">{totalMinted}/8888 minted</div>
      </div>

      {latestMint.length > 0 && (
        <div className="my-20 home-mints">
          <div className="mb-8 inline-flex">
            <h1 className="text-lg font-semibold">Latest Pallettes Minted</h1>
          </div>
          <Row gutter={[16, 16]}>
            {latestMint.map((file, i) => (
              <Col className="mb-3" md={6} sm={12} xs={24} key={file.name}>
                <Card
                  bordered
                  hoverable
                  title={<div>{file.name}</div>}
                  cover={<img alt={file.name} className="object-cover" src={file.image} />}
                >
                  <div>
                    {/* <div className="mb-2">Minted by</div> */}
                    <Address
                      address={file.owner}
                      ensProvider={mainnetProvider}
                      fontSize={16}
                      noCopy={true}
                      noLink={true}
                    />
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}

export default Home;
